'use strict';

const { bindGA, getText } = require('./util/analytics-util');
const { queryFirst, queryAll, hasClass } = require('lilly/domUtil');
const { LOYALTY_ENROLLMENT_EVENT_NAME, LOYALTY_PROGRAM_ACTION, LOYALTY_PROGRAM_SUCCESS_ENROLL } = require('./util/constants');
/**
 * Analytics for Login / Create Account
 */
function initEvents() {
    const CATEGORY_LOGIN = 'account sign in';
    const ACTION_SIGN_IN = 'sign in';
    const ACTION_CREATE_ACCOUNT = 'create an account';
    const { utag_data: uData = {} } = window;
    const { body } = document;
    const loginForm = queryFirst('form.login');
    const resetPasswordForm = queryFirst('.reset-password-form');
    const registerForm = queryFirst('form.registration');

    if (loginForm) {
        // Login Submit
        bindGA(loginForm, {
            event_category: CATEGORY_LOGIN,
            event_action: ACTION_SIGN_IN,
            event_label: 'submit'
        }, {
            eventType: 'login:submit',
            isCustomEvent: true
        });

        // Login Error
        bindGA(loginForm, () => {
            const failureReason = queryFirst('.alert,.alert-danger');
            return {
                event_category: CATEGORY_LOGIN,
                event_action: ACTION_SIGN_IN,
                event_label: 'failure',

                // GA4
                event_name: 'login_failure',
                failure_reason: getText(failureReason)
            };
        }, {
            eventType: 'login:error',
            isCustomEvent: true
        });

        // Login success
        bindGA(loginForm, {
            event_category: CATEGORY_LOGIN,
            event_action: ACTION_SIGN_IN,
            event_label: 'success',

            // GA4
            event_name: 'login_complete'

        }, {
            eventType: 'login:success',
            isCustomEvent: true
        });

        // Forgot Password Link Click
        bindGA(body, {
            event_category: CATEGORY_LOGIN,
            event_action: ACTION_SIGN_IN,
            event_label: 'forgot password'
        }, {
            bindOptions: {
                targetSelectors: ['#password-reset']
            }
        });

        // Forgot Password Submit
        bindGA(resetPasswordForm, {
            event_category: CATEGORY_LOGIN,
            event_action: ACTION_SIGN_IN,
            event_label: 'forgot password submit'
        }, {
            eventType: 'login:register', // not a mistake -- this is how it's defined all the way back to the base cartridge
            isCustomEvent: true
        });

        // Create Account Button Click
        bindGA(body, {
            event_category: CATEGORY_LOGIN,
            event_action: ACTION_CREATE_ACCOUNT,
            event_label: 'start'
        }, {
            bindOptions: {
                targetSelectors: ['.create-account-btn', '.create-account-link'], // create-account-link is on wishlist
                retriggerEvent: false,
                once: true
            }
        });
    }

    if (registerForm) {
        // Create Account Submit
        bindGA(registerForm, {
            event_category: CATEGORY_LOGIN,
            event_action: ACTION_CREATE_ACCOUNT,
            event_label: 'submit'
        }, {
            eventType: 'login:register',
            isCustomEvent: true
        });

        // Create Account Error
        bindGA(registerForm, (bindElement) => {
            const elements = queryAll('.is-invalid', bindElement);
            const failureReasons = [];

            elements.forEach((element) => {
                const childrenElm = Array.from(element.offsetParent.children);
                childrenElm.forEach((child) => {
                    if (hasClass(child, 'invalid-feedback')) {
                        failureReasons.push(getText(child));
                    }
                });
            });

            return {
                event_category: CATEGORY_LOGIN,
                event_action: ACTION_CREATE_ACCOUNT,
                event_label: 'failure',

                // GA4
                event_name: 'registration_failure',
                failure_reason: failureReasons
            };
        }, {
            eventType: 'register:error',
            isCustomEvent: true
        });

        // Create Account Success
        bindGA(registerForm, {
            event_category: CATEGORY_LOGIN,
            event_action: ACTION_CREATE_ACCOUNT,
            event_label: 'success',

            // GA4
            event_name: 'registration_complete'

        }, {
            eventType: 'register:success',
            isCustomEvent: true,
            bindOptions: {
                once: true
            }
        });
        // Trigger GA event on load of loyalty opt in checkbox
        bindGA(registerForm, {
            event_name: LOYALTY_ENROLLMENT_EVENT_NAME,
            interaction_type: LOYALTY_PROGRAM_ACTION,
        }, {
            eventType: 'register:optin_load',
            isCustomEvent: true,
            bindOptions: {
                once: true
            }
        });

        // Fire upon having checkbox checked and clicking submit in Create an Account page
        bindGA(registerForm, (bindElement, srcElement) => {
            // const container = bindElement.closest('.register-sheet') || bindElement.closest('form');
            const clubLillyCheckboxContainer = queryFirst('.loyalty-program-checkbox', bindElement);
            const clubLillyCheckbox = queryFirst('.add-to-loyalty-program-checkbox', clubLillyCheckboxContainer);
            if (!clubLillyCheckbox) return false;
            if (clubLillyCheckbox.checked) {
                return {
                    // GA4
                    page_name: uData.page_name,
                    page_type: uData.page_type,
                    loyalty_status: uData.loyalty_status,
                    logged_in_status: uData.user_loggedin_status,
                    event_name: LOYALTY_ENROLLMENT_EVENT_NAME,
                    interaction_type: LOYALTY_PROGRAM_SUCCESS_ENROLL
                };
            }
            return false;
        }, {
            eventType: 'register:success',
            isCustomEvent: true,
            bindOptions: {
                retriggerEvent: false,
                once: true
            }
        });
    }
    // check order status
    bindGA(body, (bindElement, srcElement) => {
        // GA4
        const checkorderStatus = queryFirst('.checkorder-status');
        if (!checkorderStatus) return false;
        const { isWebGuest } = checkorderStatus.dataset;
        const userStatus = isWebGuest === 'true' ? 'pre login check' : 'account check';

        return {
            event_name: 'check_order_status',
            click_text: getText(srcElement),
            interaction_type: userStatus

        };
    }, {
        bindOptions: {
            targetSelectors: ['.track-order-btn']
        }
    });
}

initEvents();
