'use strict';

const { bind, queryAll, queryFirst, hasClass } = require('lilly/domUtil');
const { toDatasetKey, hashValue } = require('lilly/util');
const { VIEW_PROMO, SELECT_PROMO, SELECT_ITEM, VIEW_ITEM_LIST, ADD_TO_CART, P_PRODUCT_LIST_KEY, P_PROMO_NAME_KEY, P_PROMO_CREATIVE_NAME } = require('./constants');

// Persisted Data Constants
const PERSISTED_DATA_NAME = 'utag_persisted';
const STORAGE_EXP_TIME = 30 * 60 * 1000; // 30 minutes

const gaInitKeyPrefix = 'ga-init';
const gaRetriggerFlag = 'gaRetrigger';
const gaFieldPipe = '|';
const customEventPattern = /\W/;

// Bindings registry
if (!window.gaBindings) window.gaBindings = {};
const { gaBindings } = window;

// A list of properties that, when set by the user, should be updated in the UDO.
// More easily put, anything that you want an up-to-date value for, which would be
// sent with ANY event type, should be in this list.
const UDOProperties = [
    'logged_in_status',
    'page_type',
    'page_category',
    'page_subcategory',
    'product_id',
    'product_name',
    'product_color',
    'product_category',
    'product_variant',
    'product_quantity',
    'product_price',
    'product_size',
    'product_sku',
    'product_original_price',
    'variant_group',
    'product_is_preorder',
    'affiliation',
    'product_badge'
];

// A list of properties that should be preserved as arrays, not converted to piped strings.
const UDOArrays = [
    'product_id',
    'product_name',
    'product_color',
    'product_category',
    'product_variant',
    'product_quantity',
    'product_price',
    'product_size',
    'product_sku',
    'product_original_price',
    'promo_id',
    'promo_name',
    'promo_creative',
    'promo_position',
    'product_is_preorder',
    'affiliation',
    'product_list_id',
    'product_list_name',
    'product_list_price',
    'product_list_position',
    'product_list_original_price',
    'product_badge',
    'product_list_category',
    'product_list_sku'
];

// A list of GA4 events (event_name) which should include promotional information if available.
// begin_checkout and purchase also need this information, but are handled in the events themselves
const promoSensitiveEvents = [VIEW_PROMO, SELECT_PROMO, ADD_TO_CART];
// a list of GA4 events (event_name) which should include product_list information if available
// begin_checkout and purchase also need this information, but are handled in the events themselves
const productListSensitiveEvents = [VIEW_ITEM_LIST, SELECT_ITEM, ADD_TO_CART];

/**
 *  Normalizes analytics data to ensure consistency.
 *  @param {*} fields - Analytics data
 *  @returns {string} - Normalized analytics value
 *  @example normalize(['a','b','c'], 1,2,3, 'x','y','z') // a|b|c|1|2|3|x|y|z
 */
const normalize = (...fields) =>
    fields
        .map(field => {
            if (!Array.isArray(field)) field = [field];

            return field
                .reduce((result, item) => {
                    if (String(item) === '[object Object]') {
                        result = result.concat(Object.values(item));
                    } else if (Array.isArray(item)) {
                        result = result.concat(normalize(item));
                    } else {
                        result.push(item);
                    }

                    return result;
                }, [])
                .map(text => (typeof text === 'undefined' ? '' : String(text).toLowerCase().trim()))
                .join(gaFieldPipe);
        })
        .join(gaFieldPipe);

/**
 *  Normalizes analytics data to ensure consistency.
 *  @param {*} field - Analytics data
 *  @returns {Array} - Normalized analytics array of values
 *  @example normalizeArray(['A','b','C', true, false, ['X', 'Y', 3], 0]) // ["a", "b", "c", "true", "false", "x|y|3", "0"]
 */
const normalizeArray = field => {
    if (!Array.isArray(field)) field = [field];

    return field.map(value => normalize(value));
};

/**
 * Normalizes a value to a string or an array based on UDOArrays
 * @param {string} key The key whose value is being normalized
 * @param {*} value The value to be normalized
 * @returns {string|Array} Normalized array or string value
 */
const smartNormalize = (key, value) => (UDOArrays.includes(key) ? normalizeArray(value) : normalize(value));

/**
 *  Normalizes an analytics payload to ensure consistency, preserving UDO properties as arrays.
 *  @param {Object} payload - Analytics data object
 *  @returns {Object} - Normalized analytics object
 *  @example normalizePayload({ event_category: "SOME CATEGORY", event_action: "SOME ACTION", event_label: undefined }) // { event_category: "some category", event_action: "some action", event_label: "" }
 */
const normalizePayload = payload =>
    Object.entries(payload).reduce((result, [key, value]) => {
        result[key] = smartNormalize(key, value);

        return result;
    }, {});

/**
 *  Retrieves contextual data about the page.
 *  @param {element} srcElement - The element that triggered the event
 *  @param {Object} [options={}] - options object from bindGA
 *  @returns {Object} - Plain object keyed with custom dimensions
 */
const getPageContext = (srcElement, options = {}) => {
    const { utag_data: uData = {} } = window;
    let sectionSrcElement = srcElement;
    const dialog = srcElement.closest && srcElement.closest('.modal');

    // Dialogs should provide context into what triggered them
    if (dialog) {
        sectionSrcElement = $(dialog).data('srcElement') || srcElement;

        if (sectionSrcElement instanceof jQuery) {
            sectionSrcElement = sectionSrcElement[0] || srcElement;
        }
    }

    // Try to determine the page section & subsection
    let pageSection = 'content';
    let pageSubsection = '';

    if (sectionSrcElement && srcElement) {
        if (sectionSrcElement.closest) {
            // Section
            if (sectionSrcElement.closest('#minicart')) {
                pageSection = 'mini tote drawer';
            } else if (sectionSrcElement.closest('.recently-viewed')) {
                pageSection = 'recently viewed';
            } else if (sectionSrcElement.closest('.style-component-container')) {
                pageSection = 'pdp style component';
            } else if (sectionSrcElement.closest('.product-recommendations.ways-to-wear')) {
                pageSection = 'ways to wear';
            } else if (sectionSrcElement.closest('.recommendations.related-products')) {
                pageSection = 'related products';
            } else if (sectionSrcElement.closest('.shop-the-print')) {
                pageSection = 'shop the print';
            } else if (sectionSrcElement.closest('.full-suggestions')) {
                pageSection = 'search recommendations';
            } else if (sectionSrcElement.closest('.shop-the-print-tiles')) {
                pageSection = 'shop the print';
            } else if (sectionSrcElement.closest('.product-recommendations')) {
                pageSection = 'product recommendations';
            } else if (sectionSrcElement.closest('.shop-by-style')) {
                pageSection = 'clp filter product carousel';
            } else if (sectionSrcElement.closest('.category-landing-page .product-recommendations')) {
                pageSection = 'clp top product carousel';
            } else if (sectionSrcElement.closest('.refinement-bar') || sectionSrcElement.closest('.subcategory-refinement-bar')) {
                pageSection = 'filters';
            } else if (sectionSrcElement.closest('.main-nav')) {
                pageSection = 'main navigation';
            } else if (sectionSrcElement.closest('.product-grid-container')) {
                pageSection = 'product grid';
            } else if (sectionSrcElement.closest('.product-detail')) {
                pageSection = 'product details';
            } else if (sectionSrcElement.closest('.main-header')) {
                pageSection = 'header';
            } else if (sectionSrcElement.closest('footer')) {
                pageSection = 'footer';
            }
        }

        if (srcElement.closest) {
            // Subsection
            if (srcElement.closest('.quick-view-dialog')) {
                pageSubsection = 'quickview dialog';
            } else if (srcElement.closest('.quickview-container')) {
                pageSubsection = 'quickview inline';
            } else if (srcElement.closest('.wishlist-details-tab')) {
                pageSubsection = 'wishlist';
            }

            // page_subsection for cart nav/SFL
            const isCartPage = queryFirst('.cart-page');
            const cartNav = srcElement.closest('.cart-nav');
            const removeDialog = srcElement.closest('.remove-product-dialog'); // remove dialog is not within cart-nav
            if (isCartPage) {
                if (cartNav || removeDialog) {
                    const selectedTab = queryFirst('.sfl-tabs-selected');
                    pageSubsection = hasClass(selectedTab, 'save-for-later-tab') ? 'saved for later' : 'my tote';
                } else {
                    // sfl disabled
                    pageSubsection = 'my tote';
                }
            }
        }
    }

    let uaAttributes = {
        // Defaults only - these should be set at the bind level or in data attributes
        event_label: '',
        event_category: '',
        event_action: ''
    };

    // Remove UA defaults if the omitUADefaults option is true
    if (options.omitUADefaults) {
        uaAttributes = {};
    }

    return {
        page_section: pageSection,
        page_subsection: pageSubsection,

        // Set in Tealium TagHelper
        caching_enabled: uData.caching_enabled,
        logged_in_status: uData.user_loggedin_status,
        affiliation: uData.affiliation,
        loyalty_status: uData.loyalty_status,
        loyalty_program_member: uData.loyalty_program_member,
        page_name: document.title,
        page_type: uData.page_type,
        page_category: uData.page_category,
        page_subcategory: uData.page_subcategory,

        product_category: uData.product_category,
        product_color: uData.product_color,
        product_id: uData.product_id,
        product_original_price: uData.product_original_price,
        product_name: uData.product_name,
        product_price: uData.product_price,
        product_quantity: uData.product_quantity && uData.product_quantity.length ? uData.product_quantity : [1],
        product_size: uData.product_size,
        product_variant: uData.product_variant,
        product_badge: uData.product_badge,

        ...uaAttributes
    };
};

/**
 * Retrieves the requested data from the persisted data in Local Storage
 * @param {string|undefined} key - the key to pull -- otherwise the entire object will be returned.
 * @returns {string|Object|undefined} the value corresponding to the supplied key (if available), the entire object (if no key was given) or undefined (if no suitable value was found)
 */
function getStoredDataLayerInfo(key) {
    const { localStorage } = window;
    const dataStr = localStorage.getItem(PERSISTED_DATA_NAME);
    if (!dataStr) return undefined;

    let data;
    try {
        data = JSON.parse(dataStr);
    } catch (e) {
        console.error(e);
        localStorage.removeItem(PERSISTED_DATA_NAME);
        return undefined;
    }

    // if there is no key, return the full data object.
    if (typeof key === 'undefined') return data;

    // if the key does not exist in the data, return undefined
    if (!(key in data)) return undefined;

    // check if the data is still valid
    const { value, timestamp } = data[key];
    const validUntil = timestamp + STORAGE_EXP_TIME;

    // if the data has expired, delete it, save back to local storage, and return undefined.
    if (validUntil < Date.now()) {
        delete data[key];
        localStorage.setItem(PERSISTED_DATA_NAME, JSON.stringify(data));
        return undefined;
    }

    return value;
}

/**
 * Persists the given data to Local Storage
 * @param {string} key - the key for this item
 * @param {string} value - the value for this item
 */
function setStoredDataLayerInfo(key, value) {
    const { localStorage } = window;
    const data = getStoredDataLayerInfo() || {};
    data[key] = {
        value,
        timestamp: Date.now()
    };

    localStorage.setItem(PERSISTED_DATA_NAME, JSON.stringify(data));
}

/**
 * Stores any data that should be persisted from the payload in Local Storage
 * @param {Object} payload - The payload sent to Tealium
 */
function storePersistentData(payload) {
    const { event_name: eventName, category_id: productList, promo_name: promoName, promo_creative: promoCreative } = payload;

    if (promoName && !Array.isArray(promoName)) {
        console.warn(`promo_name for event ${eventName} is not an array!`);
    }

    if (eventName === VIEW_ITEM_LIST && productList) setStoredDataLayerInfo(P_PRODUCT_LIST_KEY, productList);
    if (eventName === SELECT_PROMO && promoName) setStoredDataLayerInfo(P_PROMO_NAME_KEY, promoName);
    if (eventName === SELECT_PROMO && promoCreative) setStoredDataLayerInfo(P_PROMO_CREATIVE_NAME, promoCreative);
}

/**
 * Sends an event to GA
 * @param {Object} data - The data to send (usually contains an event_category, event_action, and event_label)
 * @param {string} eventType - The type of event to send (e.g. link, view, etc)
 * @returns {Promise} a Promise
 */
function sendEventGA(data, eventType = 'link') {
    return new Promise((resolve, reject) => {
        const { utag } = window;

        if (utag && typeof utag[eventType] === 'function') {
            utag[eventType](data, () => {
                resolve();
            });
        } else {
            reject(new Error(`utag is missing or utag.${eventType} does not exist`));
        }
    });
}

/**
 * Sends a GA event with context and normalized payload options
 * @param {Object} data - the baseline payload to send
 * @param {HTMLElement} [contextElem] - The element to reference for context (typically the element with which the user interacted). Default: first container in main-content.
 * @param {string} [eventType='link'] - The type of event to send (e.g. link, view, etc)
 * @param {boolean} [normalizeData=true] - whether to run the payload through the normalization function
 * @returns {Promise} a promise
 */
function sendEventGAWithContext(data, contextElem = queryFirst('#main-content > .container'), eventType = 'link', normalizeData = true) {
    // omit UA defaults by default -- we shouldn't be using any UA properties anymore
    const pageContext = getPageContext(contextElem, { omitUADefaults: true });

    // merge context and data, data gets precedence
    let payload = Object.assign({}, pageContext, data);

    if (normalizeData) {
        payload = normalizePayload(payload);
    }

    return sendEventGA(payload, eventType);
}

/**
 * Checks if the event was triggered via jQuery.
 *
 * jQuery-triggered events usually have the `originalEvent` property,
 * which stores the native browser event. This function checks for that
 * property to determine if the event was likely dispatched by jQuery.
 *
 * @param {Event} e - The event object to check.
 * @returns {boolean} - Returns `true` if the event was triggered by jQuery, otherwise `false`.
 *
 * @example
 * document.addEventListener('click', function(e) {
 *   if (isJQueryEvent(e)) {
 *     console.log('Event triggered by jQuery');
 *   } else {
 *     console.log('Event triggered by native JS or user interaction');
 *   }
 * });
 */
function isJQueryEvent(e) {
    return !!e.originalEvent || e instanceof jQuery.Event;
}

/**
 * Dispatches a new event based on the provided existing event.
 *
 * The new event will inherit the type, bubbling, cancelability, and other properties
 * from the original event. If the original event is a CustomEvent, its `detail` property
 * will also be copied to the new event.
 * 
 * @param {Event} e - The original event to base the new event on.
 * @param {HTMLElement} target - Original Element where event was triggered.
 * @throws {TypeError} If the event target is not valid or the event cannot be dispatched.
 * 
 * @example
 * document.querySelector('form').addEventListener('submit', function(e) {
 *   dispatchBasedOnExistingEvent(e);
 * });
 */
function dispatchBasedOnExistingEvent(e, target) {
    if (isJQueryEvent(e)) {
        $(target).trigger(e.type);

        return;
    }

    // Create a new event based on the type of the existing event
    const newEvent = new e.constructor(e.type, {
        bubbles: e.bubbles,
        cancelable: e.cancelable,
        composed: e.composed
    });

    // If the event has additional properties, we pass them (for CustomEvent)
    if (e instanceof CustomEvent) {
        newEvent.detail = e.detail; // transfer detail from the original CustomEvent
    }

    // dispatch a new event on the same element
    e.target.dispatchEvent(newEvent);
}

/**
 * Sets up a middleware GA handler for an element
 * @param {Element} elements - The element to bind to
 * @param {Object|function} data - The data to send to GA or a callback that returns the data to send
 * @param {Object} [options={}] - Additional options to configure the event binding
 * @param {Object} [options.bindOptions={}] - Options passed to the bind method as its options parameter
 * @param {function} [options.callback] - Callback to run after the event is triggered and data is sent to GA
 * @param {string} [options.eventType='click'] - The type of event to listen for (E.g. click, change, etc)
 * @param {function} [options.eventCondition] - A function that runs when the event is triggered, but before the data is sent to GA. Returning false will abort sending data to GA.
 * @param {boolean} [options.isCustomEvent=false] - Whether the event is custom. Event types containing non-word characters are flagged custom. Custom events are bound using jQuery.
 * @param {boolean} [options.normalize=true] - Whether the data payload should be normalized.
 * @param {boolean} [options.retriggerEvent=true] - Whether to retrigger the original event, after sending data to GA. Usually you don't want to retrigger custom events.
 * @param {boolean} [options.usesContext=true] - Whether to mix page context in with the data payload.
 * @param {boolean} [options.usesDataset=true] - Whether to pull event category, action and label from from the element's dataset.
 * @param {boolean} [options.updatesUDO=true] - Whether the Universal Data Object should be updated with any applicable values from the data payload after it is sent to GA.
 * @param {boolean} [options.omitUADefaults=false] - Whether UA/360 attributes should be omitted when adding default values to this event. If false, event label, action, and category will be added.
 */
function bindGA(elements, data, options = {}) {
    const {
        bindOptions = {},
        callback,
        eventType = 'click',
        eventCondition,
        isCustomEvent = false,
        normalize: usesNormalize = true,
        retriggerEvent = true,
        usesContext = true,
        usesDataset = true,
        updatesUDO = true,
        gaEventType = 'link',
        omitUADefaults = false
    } = options;

    const { utag_data: uData } = window;

    let nodeList = elements;

    // Convert NodeList to array
    if (elements instanceof NodeList) {
        nodeList = [].slice.call(elements, 0);
    } else if (!Array.isArray(elements)) {
        nodeList = [elements];
    }

    // Default once to false (GA will filter duplicates per Shelly)
    if (typeof bindOptions.once !== 'boolean') {
        bindOptions.once = false;
    }

    if (typeof bindOptions.eventCondition !== 'function') {
        bindOptions.eventCondition = eventCondition;
    }

    // Custom events are bound with jQuery
    if (isCustomEvent || customEventPattern.test(eventType)) {
        bindOptions.bindEngine = 'jQuery';
    }

    const initFlag = [gaInitKeyPrefix, eventType, toDatasetKey(bindOptions.targetSelectors || 'root'), hashValue(data)].join('-');

    nodeList.forEach(element => {
        const binding = gaBindings[initFlag];
        const isRebind = binding && binding.includes(element);

        if (element && !isRebind) {
            // Register this binding to the element
            if (!binding) gaBindings[initFlag] = [];

            gaBindings[initFlag].push(element);

            bind(
                element,
                eventType,
                (e, target) => {
                    // Don't retrigger if we're holding down a modifier key, since we cannot retrigger using modifiers without user interaction
                    const willRetrigger = retriggerEvent && !e.ctrlKey && !e.shiftKey;

                    // Ignore clicks triggered by bindGA
                    if (e.gaRetriggered || (target.dataset && target.dataset[gaRetriggerFlag] === initFlag)) {
                        e.gaRetriggered = true;

                        if (target.dataset) {
                            delete target.dataset[gaRetriggerFlag];
                        }

                        return;
                    }

                    const tagPayload = {};

                    // Pull the payload from the target element dataset
                    if (usesDataset && target.dataset) {
                        const { eventCategory, eventAction, eventLabel } = target.dataset;

                        tagPayload.event_category = eventCategory;
                        tagPayload.event_action = eventAction;
                        tagPayload.event_label = eventLabel;
                    }

                    // Get the bindGA payload
                    const bindPayload = typeof data === 'function' ? data(element, target, e, tagPayload) : data;

                    // Trigger was aborted by callback
                    if (bindPayload === false) {
                        return;
                    }

                    // if event is promo or product list sensitive, add that data to tagPayload
                    const { event_name: eventName } = bindPayload;
                    if (eventName) {
                        if (promoSensitiveEvents.includes(eventName)) {
                            const promoName = getStoredDataLayerInfo(P_PROMO_NAME_KEY);
                            if (promoName) Object.assign(tagPayload, { promo_name: promoName });
                        }

                        if (productListSensitiveEvents.includes(eventName)) {
                            const productListName = getStoredDataLayerInfo(P_PRODUCT_LIST_KEY);
                            if (productListName) Object.assign(tagPayload, { product_list: productListName, product_list_category: [productListName] });
                        }
                        if (promoSensitiveEvents.includes(eventName)) {
                            const promoCreativeName = getStoredDataLayerInfo(P_PROMO_CREATIVE_NAME);
                            if (promoCreativeName) Object.assign(tagPayload, { promo_creative: promoCreativeName });
                        }
                    }

                    // Merge payloads (bindGA data takes priority)
                    let payload = Object.assign({}, tagPayload, bindPayload);

                    // Stop everything until we re-trigger
                    if (willRetrigger) {
                        e.preventDefault();
                        e.stopPropagation();
                    }

                    // Common page context
                    const pageContext = getPageContext(target, options);

                    if (usesContext) {
                        payload = Object.assign({}, pageContext, payload);
                    }

                    // Remove product data from promo events - PT-11362
                    if ([VIEW_PROMO, SELECT_PROMO].includes(eventName)) {
                        payload = Object.fromEntries(Object.entries(payload).filter(([key]) => !/^product_/.test(key)));
                    }

                    // Preserved UDO Properties
                    // Any properties beginning with double underscore (__) will be sent with the payload,
                    // but will be reverted in the UDO after sending
                    const preservedUDOProperties = Object.keys(payload).reduce((result, key) => {
                        if (key.substring(0, 2) !== '__') return result;

                        const property = key.slice(2);
                        payload[property] = payload[key];
                        delete payload[key];
                        result[property] = uData[property];
                        return result;
                    }, {});

                    // Do we have any lazy UDO updates?
                    const lazyUDOProperties = Object.keys(payload).reduce((result, key) => {
                        if (key.charAt(0) !== '_') return result;

                        const property = key.slice(1);
                        payload[property] = payload[key];
                        result.push(property);
                        delete payload[key];

                        return result;
                    }, []);

                    // Normalize all values
                    if (usesNormalize) {
                        payload = normalizePayload(payload);
                    }

                    // If this updates the UDO, store the updates.
                    // The UDO will be updated after the payload is sent.
                    const UDOUpdates = updatesUDO
                        ? Object.keys(payload).reduce((result, key) => {
                            // UDO properties only
                            if (!UDOProperties.includes(key)) return result;

                            // Store the updated value
                            result[key] = payload[key];

                            // For lazy properties, send the old UDO value in the payload. We'll update after.
                            if (lazyUDOProperties.includes(key)) {
                                payload[key] = usesNormalize ? smartNormalize(key, uData[key]) : uData[key];
                            }

                            return result;
                        }, {})
                        : {};

                    storePersistentData(payload);

                    sendEventGA(payload, gaEventType).finally(() => {
                        if (typeof callback === 'function') callback(element, target, e);

                        if (willRetrigger) {
                            target.dataset[gaRetriggerFlag] = initFlag;

                            // Without a teeny tiny timeout our click gets trapped
                            setTimeout(() => {
                                dispatchBasedOnExistingEvent(e, target);
                            }, 0);
                        }

                        // Update Tealium UDO
                        // This can only happen if we're updating the MAIN product,
                        // not another product on the same page (E.g. quickview, recommendations, etc)
                        if (updatesUDO && pageContext.page_section === 'product details') {
                            // Product sets need to be updated based on index
                            if (pageContext.page_type === 'product set') {
                                const productSetItem = target.closest('.product-set-item');
                                const productSetItems = queryAll('.product-set-item', target.closest('.product-set-detail'));
                                const productSetIndex = productSetItems.indexOf(productSetItem);

                                if (productSetIndex !== -1) {
                                    Object.keys(UDOUpdates).forEach(key => {
                                        const value = UDOUpdates[key];

                                        if (UDOArrays.includes(key)) {
                                            if (!uData[key]) uData[key] = new Array(productSetItems.length).fill('');
                                            if (!Array.isArray(uData[key])) uData[key] = String(uData[key]).split(gaFieldPipe);

                                            if (~String(value).indexOf(gaFieldPipe)) {
                                                uData[key][productSetIndex] = String(value).split(gaFieldPipe)[productSetIndex] || '';
                                            } else {
                                                uData[key][productSetIndex] = normalize(value);
                                            }
                                        } else {
                                            uData[key] = value;
                                        }
                                    });
                                }
                            } else {
                                Object.keys(UDOUpdates).forEach(key => {
                                    const value = UDOUpdates[key];

                                    uData[key] = Array.isArray(value) ? value : [value];
                                });
                            }
                        }

                        // Restore preserved UDO property values
                        if (Object.keys(preservedUDOProperties).length) {
                            Object.assign(uData, preservedUDOProperties);
                        }
                    });
                },
                bindOptions
            );
        } else if (isRebind) {
            console.warn('WARNING: Attempted duplicate', eventType, 'binding on', element);
        }
    });
}

/**
 * Gets the text node content of an element
 * @param {HTMLElement} elem - The element whose text node content will be returned
 * @returns {String} The content of the text node or empty string if no text node is found
 */
function getText(elem) {
    const textNode = [...elem.childNodes].find(child => child.nodeType === Node.TEXT_NODE);
    return (textNode && textNode.textContent.trim().replace(/\s+/g, ' ')) || '';
}

module.exports = {
    bindGA,
    sendEventGA,
    sendEventGAWithContext,
    getPageContext,
    normalize,
    normalizePayload,
    delimiter: gaFieldPipe,
    getText,
    storePersistentData,
    getStoredDataLayerInfo
};
