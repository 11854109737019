'use strict';

require('./gaTulip'); // Tulip events - needed for footer, PDP, content, and store locator pages
require('./gaLoyaltyProgram');
require('./gaProductCommon'); // Product event for all pages where tile could shows and interact
require('./gaLogin'); // to track login form on each page

const { bindGA, normalize, sendEventGA, normalizePayload, getText, sendEventGAWithContext } = require('./util/analytics-util');
const { bind, getProductData, queryFirst, queryAll, hasClass, isInViewport, throttle, isVisible, getSalesPrice, hasAnyClass } = require('lilly/domUtil');
const { isMobile, getItemFromLocalStorage } = require('lilly/util');
const { SELECT_PROMO, SELECT_ITEM, CONTENT_SLOT_SELECTORS, VIEW_ITEM_LIST, LOYALTY_ENROLLMENT_EVENT_NAME, NEWSLETTER_SIGNUP_COMPLETE, NEWSLETTER_SIGNUP_FAILURE, LOYALTY_PROGRAM_ACTION, VIEW_ITEM } = require('./util/constants');

const CONTENT_SLOTS = 'content slots';
const ECOMMERCE = 'ecommerce';
const NAVIGATION = 'navigation';
const BLOG_INTERACTION = 'blog_interaction';
const GLOBAL_FOOTER_LINKS = 'global footer links';
const GLOBAL_BUTTONS = 'global buttons';
const SUBMISSIONS = 'submissions';
const SEARCH_SUGGESTIONS = 'search suggestions';
const VIEW_PROMOTION = 'view_promotion';
const SEARCH_EVENT = 'search';
const MAIN_MENU = 'main menu';
const SHOPPABLE_MEDIA_WIDGET = 'shoppable media widget';
const SUBMENU = 'submenu';
const INITIATE_CHAT = 'initiate_chat';
const VIEW_SEARCH_RESULTS = 'view_search_results';
const OUTBOUND_LINK = 'outbound_link';
const GENERAL_LINK = 'general link';
const SOCIAL_LINK = 'social link';

let impressionObserver;
let shoppableObserver;
const contentSlotMap = CONTENT_SLOT_SELECTORS.map(container => `${container} a, ${container} button`);
const contentSlotStr = CONTENT_SLOT_SELECTORS.join(', ');
const ignoreImpressionsClass = 'no-ga-impressions';
const ignoreClicksClass = 'no-ga-clicks';
const ignoreSizeClicksClass = ['size-btn', 'js-edit-size-btn', 'js-select-size-btn'];

/**
 * Initializes intersection observer for content slot impressions
 */
function initContentImpressions() {
    if (!impressionObserver) {
        const { utag_data: uData = {} } = window;
        impressionObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                // Is the slot in viewport?
                const { target } = entry;
                if (entry.isIntersecting && isVisible(target)) {
                    const containerClass = CONTENT_SLOT_SELECTORS.find(selector => hasClass(target, selector.slice(1)));
                    const slotPosition = queryAll(containerClass).indexOf(target) + 1;
                    const { moduleName, promoId, promoName, promoCreative } = target.dataset;
                    const targetLink = queryFirst('a', target);
                    const eLabel = (targetLink && targetLink.href) || [slotPosition, moduleName];

                    sendEventGA(normalizePayload({
                        event_name: VIEW_PROMOTION,
                        event_category: CONTENT_SLOTS + ' view',
                        event_action: VIEW_PROMOTION,
                        event_label: eLabel,
                        promo_id: promoId ? [promoId] : [],
                        promo_name: promoName ? [promoName] : [],
                        promo_creative: promoCreative ? [promoCreative] : [],
                        promo_position: [slotPosition]
                    }));

                    observer.unobserve(target);
                }
            });
        }, {
            rootMargin: '0px 0px',
            threshold: 0.05
        });
    }

    const contentSlots = queryAll(contentSlotStr);
    contentSlots.forEach(slot => {
        // ensure no duplicate observers get bound
        if (slot.dataset.gaImpressions !== 'true' && !hasClass(slot, ignoreImpressionsClass)) {
            impressionObserver.observe(slot);
            slot.dataset.gaImpressions = 'true'; // eslint-disable-line no-param-reassign
        }
    });
}

/**
 * Initializes intersection observer for shoppable media
 */
function initShoppableMediaEvent() {
    const shoppableMediaContainer = queryFirst('.shoppable-media');
    if (!shoppableObserver) {
        shoppableObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                // Is the slot in viewport?
                const { target } = entry;
                if (entry.isIntersecting && isVisible(target)) {
                    const shoppableTitle = queryFirst('.shoppable-media-title', shoppableMediaContainer).innerText;
                    const shoppableMediaType = queryFirst('.video-component', shoppableMediaContainer) ? 'video' : 'static media';
                    const shoppableProducts = queryAll('.smtile-small .product', shoppableMediaContainer);
                    const productNames = [];
                    const masterIds = [];
                    const productPrices = [];
                    const originalPrices = [];

                    // Iterate over each srcElement and extract the properties
                    shoppableProducts.forEach(srcElement => {
                        const { productName, masterId, productPrice, originalPrice } = getProductData(srcElement);
                        productNames.push(productName);
                        masterIds.push(masterId);
                        productPrices.push(productPrice);
                        originalPrices.push(originalPrice);
                    });

                    sendEventGA(normalizePayload({
                        event_name: VIEW_ITEM_LIST,
                        product_list_category: 'shoppable media ' + shoppableTitle + ' ' + shoppableMediaType,
                        product_name: productNames,
                        product_id: masterIds,
                        product_price: productPrices,
                        product_original_price: originalPrices,
                        product_list_position: new Array(shoppableProducts.length).fill().map((_, index) => index + 1)
                    }));

                    observer.unobserve(target);
                }
            });
        }, {
            rootMargin: '0px 0px',
            threshold: 0.05
        });
    }

    // ensure no duplicate observers get bound
    if (shoppableMediaContainer && shoppableMediaContainer.dataset.gaImpressions !== 'true') {
        shoppableObserver.observe(shoppableMediaContainer);
        shoppableMediaContainer.dataset.gaImpressions = 'true'; // eslint-disable-line no-param-reassign
    }
}

/**
 * Analytics for Common Elements and Events
 */
function initEvents() {
    const { utag_data: uData = {} } = window;
    const { body } = document;
    const pageType = uData.page_type || '';
    const catType = pageType === 'home' ? 'home:home' : normalize(`${uData.page_category}:${uData.category_name}`);

    const mainNav = queryFirst('.main-nav');
    const searchMenu = queryFirst('.search-menu');
    const searchForm = queryFirst('.search-form', searchMenu);
    const allVideos = queryAll('video');

    // Main Logo
    bindGA(mainNav, (bindElement, srcElement) => ({
        // GA4
        event_name: NAVIGATION,
        nav_location: MAIN_MENU,
        click_text: srcElement.title,
        click_url: srcElement.href,

        // GA360
        event_category: NAVIGATION,
        event_action: 'home',
        event_label: 'logo click'
    }), {
        bindOptions: {
            targetSelectors: ['.header-nav-logo a']
        }
    });

    // GA event triggers when user clicks on Club Lilly button below My Account in the navigation.
    bindGA(body, (bindElement, srcElement) => ({

        // GA4
        page_name: uData.page_name,
        page_type: uData.page_type,
        loyalty_status: uData.loyalty_status,
        logged_in_status: uData.user_loggedin_status,
        event_name: LOYALTY_ENROLLMENT_EVENT_NAME,
        interaction_type: LOYALTY_PROGRAM_ACTION

    }), {
        eventType: 'clubLilly:EnrollModal'
    });

    // Navigation Top-level Links
    bindGA(mainNav, (bindElement, srcElement) => {
        const { topNav } = srcElement.dataset;

        if (hasClass(srcElement, 'dropdown-toggle') && isMobile()) {
            return false;
        }

        return {
            // GA4
            event_name: NAVIGATION,
            nav_location: MAIN_MENU,
            click_text: topNav,
            click_url: srcElement.href,
            nav_breadcrumbs: topNav,

            // GA360
            event_category: NAVIGATION,
            event_action: topNav,
            event_label: topNav
        };
    }, {
        bindOptions: {
            targetSelectors: ['.menu-group .nav-item .dropdown-toggle']
        }
    });

    // Navigation Dropdown Links
    bindGA(mainNav, (bindElement, srcElement) => {
        const { topNav, subNav } = srcElement.dataset;
        const subNavElems = subNav && JSON.parse(subNav) || [];
        const breadcrumbs = [topNav, ...subNavElems];

        return {
            // GA4
            event_name: NAVIGATION,
            nav_location: SUBMENU,
            click_text: getText(srcElement),
            click_url: srcElement.href,
            nav_breadcrumbs: breadcrumbs,

            // GA360
            event_category: NAVIGATION,
            event_action: topNav,
            event_label: subNavElems
        };
    }, {
        bindOptions: {
            targetSelectors: ['.menu-group .nav-item .dropdown-link']
        }
    });

    // Navigation Dropdown Promo Card
    bindGA(mainNav, (bindElement, srcElement) => {
        let label = 'Navigation Dropdown Banner';
        const wrapper = srcElement.closest('.banner-wrapper');
        const { topNav } = wrapper.dataset;
        const links = wrapper.querySelectorAll('a');
        if (links.length) {
            label = getText(links[links.length - 1]);
        }

        return {
            // GA4
            event_name: NAVIGATION,
            nav_location: SUBMENU,
            click_text: label,
            click_url: srcElement.href,
            nav_breadcrumbs: [topNav, label],

            // GA360
            event_category: NAVIGATION,
            event_action: topNav,
            event_label: label
        };
    }, {
        bindOptions: {
            targetSelectors: ['.menu-group .banner-wrapper a']
        }
    });

    // Navigation Icons (store locator, account, wishlist, tote) - GA4 only
    bindGA(queryFirst('.header-nav-icons-right'), (bindElement, srcElement) => {
        let overrideText;

        // ordinarily we can use aria-label for text, but for cart it denotes how many items
        // the user has in cart as well, e.g. 'Cart 2 Items'
        if (hasClass(srcElement, 'minicart-link')) {
            overrideText = 'tote'
        }

        return {
            event_name: NAVIGATION,
            nav_location: MAIN_MENU,
            click_text: overrideText || srcElement.ariaLabel || getText(srcElement),
            click_url: srcElement.href || srcElement.dataset.target
        }
    }, {
        bindOptions: {
            targetSelectors: ['a:not(#myaccount)', 'button']
        },
        omitUADefaults: true,
        usesDataset: false,
        retriggerEvent: false
    });

    // Logged-in Engagement on My Account Page
    bindGA(queryAll('.redesign-account-dashboard .account-card a'), (bindElement, srcElement) => {
        const closestAccountCard = srcElement.closest('.account-card');
        const cardHeaderText = closestAccountCard ? getText(queryFirst('h2', closestAccountCard)) : '';
        const linkText = getText(srcElement);
        const interactionType = [linkText, cardHeaderText];
        // GA4
        return {
            event_name: 'logged_in_engagement',
            interaction_type: interactionType
        };
    }, {
        retriggerEvent: false
    });

    // Search submit
    bindGA(searchForm, bindElement => {
        const searchTerm = queryFirst('.search-field', bindElement).value;

        return {
            event_name: SEARCH_EVENT,
            search_type: 'search submit',
            search_term: searchTerm
        };
    }, {
        eventType: 'submit'
    });

    // Search suggestions
    bindGA(searchMenu, (bindElement, srcElement) => {
        const searchTerm = queryFirst('.search-field', bindElement).value;
        const suggestionWrapper = srcElement.closest('.suggestions-section');
        const suggestionTypeElem = queryFirst('.suggestions-section-title > span', suggestionWrapper);
        // differentiate popular search/suggestions/categories/products
        const suggestionType = suggestionTypeElem ? suggestionTypeElem.textContent.trim().toLowerCase() : 'search suggestions';
        const clickedSuggestion = srcElement.textContent.trim();

        // GA360 compatibility
        let eAction = '';
        let eLabel = '';

        if (suggestionType === 'search suggestions') {
            eAction = 'phrase suggestion';
            eLabel = clickedSuggestion;
        } else if (suggestionType === 'related products') {
            const { productName, masterId, productColor } = srcElement.dataset;

            eAction = 'product suggestion';
            eLabel = [productName, masterId, productColor];
        } else if (suggestionType === 'categories') {
            eAction = 'category suggestion';
            eLabel = clickedSuggestion;
        }

        return {
            event_name: SEARCH_EVENT,
            search_type: suggestionType,
            search_term: searchTerm || clickedSuggestion,
            search_suggestion: clickedSuggestion,

            event_category: SEARCH_SUGGESTIONS,
            event_action: eAction,
            event_label: eLabel
        };
    }, {
        retriggerEvent: false,
        bindOptions: {
            targetSelectors: ['.suggestions-section a']
        }
    });

    bindGA(body, (bindElement, srcElement, e) => {
        // doesn't send analytics if it is not 'search' OR the Density button was clicked
        if (uData.page_type !== 'search' || (e.data && e.data.isDensityChange)) return false;

        const filterResultsEl = queryFirst('.no-filter-results');
        const suggestionLink = queryFirst('.did-you-mean .link');
        const noResultSearchTerm = queryFirst('search-results-header .search-keywords');
        const searchSuggestionText = suggestionLink ? getText(suggestionLink) : '';
        const noResultSearchTermText = noResultSearchTerm ? getText(noResultSearchTerm) : '';
        const numberOfProduct = filterResultsEl && filterResultsEl.dataset.productcount;
        const searchType = getItemFromLocalStorage('searchType');
        const searchSuggestion = (searchType === 'search suggestions') ? searchSuggestionText || noResultSearchTermText || uData.search_keyword : 'undefined';
        return {
            event_name: VIEW_SEARCH_RESULTS,
            search_type: searchType,
            search_term: uData.search_keyword || '',
            num_search_results: uData.num_search_results || numberOfProduct || '',
            search_suggestion: searchSuggestion
        };
    }, {
        retriggerEvent: false,
        eventType: 'search:updateProducts'
    });

    // View Search Results Product Impression - product impression on product tiles that appear in the search preview
    bindGA(body, () => {
        const searchProductList = queryAll('.suggestions-related-products .pdp-link a');
        const allProductNames = [];
        const allProductPrices = [];
        const allMasterIds = [];
        const productVariants = [];
        const productCategories = [];
        const allProductColor = [];
        const strikeThroughPrices = [];
        const productSku = [];
        const allProductPositions = [];
        const allProductBadges = [];
        const searchTerm = queryFirst('.search-field')?.value || uData.search_term || uData.search_keyword;
        const suggestionLink = queryAll('.term.search-suggestions-item');
        const searchSuggestionText = suggestionLink.length ? suggestionLink.map(item => getText(item).replace(/'|’|‘/g, '')).join() : '';

        if (!searchProductList.length) return false;

        searchProductList.forEach((product) => {
            const { productName, masterId, variationGroupId, productPrice, productCategory, productColor, productStrikethroughPrice, firstProductSku, productPosition, productBadge } = product.dataset;

            // get adjusted sale price
            const priceFromProdEl = getSalesPrice(product.closest('.pdp-link'));
            const adjustedPrice = priceFromProdEl && priceFromProdEl !== productPrice && priceFromProdEl;

            allProductNames.push(productName);
            allProductPrices.push(adjustedPrice || productPrice);
            allMasterIds.push(masterId);
            productVariants.push(normalize([productName, variationGroupId]));
            productCategories.push(productCategory);
            allProductColor.push(productColor);
            const price = productStrikethroughPrice.trim() || productPrice;
            strikeThroughPrices.push(price);
            productSku.push(firstProductSku);
            allProductPositions.push(productPosition);
            allProductBadges.push(productBadge || '');
        });

        return {
            event_action: VIEW_ITEM_LIST,
            event_name: VIEW_ITEM_LIST,
            event_category: 'engagement',
            event_noninteraction: true,
            product_list: 'search related products',
            product_list_id: allMasterIds,
            product_list_name: allProductNames,
            product_list_price: allProductPrices,
            product_list_original_price: strikeThroughPrices,
            product_variant: productVariants,
            product_list_category: productCategories,
            product_list_position: allProductPositions,
            product_list_sku: productSku,
            search_term: searchTerm,
            search_type: 'active typing search suggestions',
            search_suggestion: searchSuggestionText,
            product_badge: allProductBadges
        };
    }, {
        retriggerEvent: false,
        eventType: 'search:suggestionsUpdated'
    });

    // Shop the look item loaded
    bindGA(body, (bindElement, srcElement, e) => {

        const highlightComponent = e.data.clickedElement.closest('.product-highlight-asset');
        const { promoId, promoName, promoCreative } = highlightComponent.dataset;
        const productList = queryAll('.js-shop-the-look .js-set-item');
        const productNames = [];
        const masterIds = [];
        const productPrices = [];
        const originalPrices = [];
        const promoIds = [];
        const promoNames = [];
        const promoCreatives = [];
        const productQuantity = [];
        const productColors = [];
        const productBadges = [];
        const productVariants = [];
        const affiliations = [];

        // Iterate over each srcElement and extract the properties
        productList.forEach(productEl => {
            const { productName, masterId, productPrice, originalPrice, productColor, productBadge, variationGroupId } = getProductData(productEl);
            productNames.push(productName);
            masterIds.push(masterId);
            productPrices.push(productPrice);
            originalPrices.push(originalPrice);
            productColors.push(productColor);
            productBadge ? productBadges.push(productBadge) : productBadges.push('');
            if (promoIds) promoIds.push(promoId);
            if (promoNames) promoNames.push(promoName);
            if (promoCreatives) promoCreatives.push(promoCreative);
            productQuantity.push(1); //by default only 1 product could be added to cart from product highlight
            affiliations.push(uData?.affiliation[0]);
            productVariants.push([productName, productColor, variationGroupId]);
        });

        return {
            event_action: VIEW_ITEM_LIST,
            event_name: VIEW_ITEM_LIST,
            promo_id: promoIds,
            promo_name: promoNames,
            promo_creative: promoCreatives,
            event_noninteraction: true,
            product_list_category: 'product highlight - shop the look',
            product_list_id: masterIds,
            product_list_name: productNames,
            product_list_price: productPrices,
            product_list_original_price: originalPrices,
            product_color: productColors,
            product_badge: productBadges,
            product_quantity: productQuantity,
            product_variant: productVariants,
            affiliation: affiliations
        };
    }, {
        retriggerEvent: false,
        eventType: 'search:shopTheLookUpdated'
    });

    // Product selection/click from Search Results
    bindGA(searchForm, (bindElement, srcElement) => {
        const { productName, masterId, variationGroupId, productPrice, productCategory, productStrikethroughPrice, productBadge, firstProductSku, productPosition, refinementColor } = srcElement.dataset;
        return {
            event_name: SELECT_ITEM,
            event_category: ECOMMERCE,
            event_action: 'product click',
            event_label: [productName, variationGroupId],
            event_noninteraction: false,
            product_name: productName,
            product_id: masterId,
            product_category: productCategory,
            product_price: productPrice,
            product_color: refinementColor,
            product_badge: [productBadge],
            product_original_price: productStrikethroughPrice.trim() || productPrice,
            product_variant: [[productName, variationGroupId]],
            product_list_position: productPosition,
            product_list_sku: firstProductSku,
            affiliation: [uData.affiliation[0]]
        };
    }, {
        bindOptions: {
            targetSelectors: ['.suggestions-related-products .pdp-link a']
        }
    });

    // Footer Links
    bindGA(queryFirst('footer'), (bindElement, srcElement, e, payload) => {
        // Use what was provided via data attribute, otherwise default to the link text
        const textContent = getText(srcElement);
        const eventAction = payload.event_action || textContent;

        return {
            // GA4
            event_name: NAVIGATION,
            nav_location: 'footer',
            click_text: textContent,
            click_url: srcElement.href,

            // GA360
            event_category: GLOBAL_FOOTER_LINKS,
            event_action: eventAction,
            event_label: pageType
        };
    }, {
        bindOptions: {
            targetSelectors: ['.menu-footer a']
        }
    });

    // Blog pages
    bindGA(queryFirst('.content-navigation'), (bindElement, srcElement) => {
        // Use what was provided via data attribute, otherwise default to the link text
        const textContent = getText(srcElement);
        const { topNav } = bindElement.dataset;

        return {
            // GA4
            event_name: NAVIGATION,
            nav_location: 'blog nav',
            click_text: textContent,
            click_url: srcElement.href,
            nav_breadcrumbs: [topNav, textContent]
        };
    }, {
        bindOptions: {
            targetSelectors: ['.content-navigation a']
        }
    });
    // Blog page back button
    bindGA(queryFirst('.blog-post-entry-nav'), (bindElement, srcElement) => {
        // Use what was provided via data attribute, otherwise default to the link text
        const textContent = getText(srcElement);
        const { topNav } = bindElement.dataset;

        return {
            // GA4
            event_name: NAVIGATION,
            nav_location: 'blog nav',
            click_text: textContent,
            click_url: srcElement.href,
            nav_breadcrumbs: topNav
        };
    }, {
        bindOptions: {
            targetSelectors: ['.blog-post-entry-nav a']
        }
    });

    // Blog content links
    bindGA(queryAll('.blog-section'), (bindElement, srcElement) => {
        // Use what was provided via data attribute, otherwise default to the link text
        const textContent = getText(srcElement);

        return {
            // GA4
            event_name: BLOG_INTERACTION,
            interaction_type: 'shop now link',
            click_text: textContent,
            click_url: srcElement.href
        };
    }, {
        bindOptions: {
            targetSelectors: ['.blog-section a']
        }
    });

    // Miscellaneous Outbound Links
    bindGA(body, (bindElement, srcElement) => {
        if (srcElement.href.indexOf('lillypulitzer') !== -1) return false;
        const linkType = srcElement.host === 'apps.apple.com' ? 'app store link' : GENERAL_LINK;
        return {
            // GA4
            event_name: OUTBOUND_LINK,
            outbound_link_type: linkType,
            click_text: getText(srcElement),
            click_url: srcElement.href
        };
    }, {
        bindOptions: {
            targetSelectors: ['.menu-footer.content li a']
        }
    });
    // Social Outbound Links
    bindGA(body, (bindElement, srcElement) => {
        const iconClicked = queryFirst('title', srcElement);
        return {
            // GA4
            event_name: OUTBOUND_LINK,
            outbound_link_type: SOCIAL_LINK,
            click_text: getText(iconClicked),
            click_url: srcElement.href
        };
    }, {
        bindOptions: {
            targetSelectors: ['#footercontent .social-link-icons a']
        }
    });
    // Footer initiate chat
    bindGA(body, (bindElement, srcElement) => {
        return {
            // GA4
            event_name: INITIATE_CHAT,
            outbound_link_type: 'live chat',
            click_text: getText(srcElement),
            click_url: srcElement.href,
            interaction_type: 'chat with customer service'
        };
    }, {
        bindOptions: {
            targetSelectors: ['.menu-footer .live-agent-online-button a']
        },
        retriggerEvent: false // MUST be set to false while the second .login-link tracker is also active. Otherwise it triggers an infinite loop and kills the sandbox
    });

    // Login Link - second event (used to track login flow)
    bindGA(queryFirst('.header-nav-icons-right'), {
        event_category: 'account sign in',
        event_action: 'sign in',
        event_label: 'start login'
    }, {
        bindOptions: {
            targetSelectors: ['.login-link']
        },
        retriggerEvent: false // MUST be set to false while the second .login-link tracker is also active. Otherwise it triggers an infinite loop and kills the sandbox
    });

    // Signed-in Account icon drop-down - link click events (Hello / My Account / Sign Out)
    bindGA(queryFirst('.user-account-dropdown-menu'), (bindElement, srcElement) => {
        let label = '';
        if (hasClass(srcElement, 'user-message')) {
            label = 'Hello';
        } else if (hasClass(srcElement, 'user-logout')) {
            label = 'Sign out';
        } else if (hasClass(srcElement, 'nav-dropdown-account')) {
            label = 'My Account';
        } else {
            return false;
        }

        return {
            event_category: 'account sign in',
            event_action: 'account hover',
            event_label: label
        };
    }, {
        bindOptions: {
            targetSelectors: ['.dropdown-item']
        },
        retriggerEvent: false
    });

    // MiniCart, Wishlist, Login Links - GA360 only
    bindGA(queryFirst('.header-nav-icons-right'), (bindElement, srcElement) => {
        let eAction = '';
        if (hasClass(srcElement, 'minicart-link')) {
            eAction = 'tote';
        } else if (hasClass(srcElement, 'login-link')) {
            eAction = 'login';
        } else if (hasClass(srcElement.parentElement, 'wishlist')) {
            eAction = 'wishlist';
        }

        return {
            event_category: GLOBAL_BUTTONS,
            event_action: eAction,
            event_label: pageType
        }
    }, {
        bindOptions: {
            targetSelectors: ['.minicart-link', '.wishlist a', '.login-link']
        },
        retriggerEvent: false
    });

    // Close Buttons
    bindGA(queryAll('.close-current-modal'), {
        event_category: GLOBAL_BUTTONS,
        event_action: 'close dialog',
        event_label: pageType
    });

    // Track Order Buttons
    bindGA(queryAll('.track-order-btn'), {
        event_category: SUBMISSIONS,
        event_action: 'check order status',
        event_label: 'order status'
    });

    // Content Slots / Modules
    bindGA(body, (bindElement, srcElement) => {
        const slot = srcElement.closest(CONTENT_SLOT_SELECTORS.join(', '));
        if (hasClass(slot, ignoreClicksClass) || hasAnyClass(srcElement, ...ignoreSizeClicksClass)) {
            return false;
        }

        const containerClass = CONTENT_SLOT_SELECTORS.find(selector => hasClass(slot, selector.slice(1)));
        const slotPosition = queryAll(containerClass).indexOf(slot) + 1;
        const { promoId, promoName, promoCreative } = slot.dataset;
        const targetUrl = srcElement.getAttribute('href') || slot.getAttribute('href') || srcElement.dataset.href || '';
        let targetButtonText = '';

        if (!targetUrl) {
            const targetButton = srcElement.closest('button');
            if (targetButton) {
                targetButtonText = targetButton.ariaLabel || targetButton.innerText || '';
            }
        }

        const eventLabel = targetUrl || targetButtonText || '';

        return {
            event_name: SELECT_PROMO,
            event_category: CONTENT_SLOTS + ' click',
            event_action: 'promotion_click',
            event_label: eventLabel,
            promo_id: promoId ? [promoId] : [],
            promo_name: promoName ? [promoName] : [],
            promo_creative: promoCreative ? [promoCreative] : [],
            promo_position: [slotPosition]
        };
    }, {
        retriggerEvent: false,
        bindOptions: {
            targetSelectors: contentSlotMap,
            once: true
        }
    });

    // Content Slot / Module Impressions
    initContentImpressions();

    // shopable media
    initShoppableMediaEvent();

    // Initialize tracking on elements loaded when switching pages/loading more products on a category page
    $('body').on('search:updateProducts', function () {
        initContentImpressions();
    });

    // Skinny banner buttons
    bindGA(body, (bindElement, srcElement) => {
        let action = '';
        if (hasClass(srcElement, 'stripe-banner-next')) {
            action = 'view next';
        } else if (hasClass(srcElement, 'close-banner')) {
            action = 'close content';
        }

        if (!action) {
            return false;
        }

        return {
            event_category: CONTENT_SLOTS + ' click',
            event_action: action,
            event_label: 'skinny banner'
        };
    }, {
        retriggerEvent: false,
        bindOptions: {
            targetSelectors: ['.stripe-banner-next', '.close-banner']
        }
    });

    // No Results Content Slot
    bindGA(queryAll('.no-results-recommendations-block a'), bindElement => ({
        event_category: CONTENT_SLOTS,
        event_action: 'no search results slot',
        event_label: bindElement.getAttribute('href') || ''
    }));

    // Inline LCAs
    bindGA(body, (bindElement, srcElement) => {
        const slot = srcElement.closest('.category-marketing-slot');
        const slotPosition = queryAll('.category-marketing-slot').indexOf(slot);
        const targetUrl = srcElement.getAttribute('href') || slot.getAttribute('href') || '';
        const eventLabel = slot.dataset.moduleName || srcElement.dataset.moduleName || srcElement.dataset.eventLabel || '';

        return {
            event_category: 'category inline lca',
            event_action: targetUrl,
            event_label: [eventLabel, (~slotPosition && slotPosition + 1) || '', srcElement.textContent || '']
        };
    }, {
        bindOptions: {
            targetSelectors: ['.category-marketing-slot a']
        }
    });

    // Product Selection
    bindGA(body, (bindElement, srcElement) => {
        const { tealiumListIds, tealiumProdStyles, tealiumProdLists } = window;
        if (!tealiumListIds) return false;

        const { masterId, productName, productColor, productBadge, productSet, productPrice } = getProductData(srcElement);

        if (srcElement.closest('.product-highlight') || srcElement.closest('.shoppable-media')) return false; // skip for highlight module. it covered via select-promotion
        const productId = srcElement.dataset.productId || '';

        const index = productSet ? tealiumListIds.indexOf(productId) : tealiumListIds.indexOf(masterId);

        if (index === -1) return false;

        const pageAction = queryFirst('.page').dataset.action;
        const styleId = tealiumProdStyles[index];
        const { tealiumProdListName = '', tealiumProdOriginalPrices = [], tealiumProdPrices = [] } = window;
        const productTile = srcElement.closest('.product-tile');

        const productCategoryElement = productTile?.querySelector('[data-product-category]');
        const productCategory = productCategoryElement && productCategoryElement.dataset.productCategory !== 'null' ? productCategoryElement.dataset.productCategory : '';
        const tealiumPrice = tealiumProdPrices[index];
        const suggestionLink = queryFirst('.term.search-suggestions-item');
        let productListCategory = [tealiumProdLists[index]];
        switch (pageAction) {
            case 'Home-Show':
                productListCategory = 'homepage lists';
                break;
            default:
                break;
        }

        return {
            event_name: SELECT_ITEM,
            product_list: uData.category_name || uData.product_list || tealiumProdListName,
            category_id: uData.category_id || '',
            search_term: uData.search_term && uData.search_term !== 'undefined' ? uData.search_term : '',
            num_search_results: uData.num_search_results || '',
            product_id: productSet ? [productId] : [masterId],
            product_name: [productName],
            product_category: [uData.category_name || productCategory || ''],
            product_variant: productSet ? [[productName]] : [[productName, styleId]],
            product_price: [productPrice && productPrice !== tealiumPrice ? productPrice : tealiumPrice],
            product_quantity: ['1'],
            product_list_position: [index + 1],
            product_original_price: [tealiumProdOriginalPrices[index]],
            product_list_category: productListCategory,

            // 360 attributes
            event_category: ECOMMERCE,
            event_action: 'product click',
            event_label: [productName, masterId, productColor],
            ecommerce_action: 'product_click',
            product_click_list: [tealiumProdListName],
            product_badge: [productBadge],
            search_type: (uData.page_type === 'search' || uData.search_term !== 'undefined') ? getItemFromLocalStorage('searchType') : '',
            search_suggestion: (suggestionLink && getText(suggestionLink)) || ''
        };
    }, {
        bindOptions: {
            targetSelectors: ['.tile-img-link', '.js-product-tile-link']
        }
    });

    // QuickView Buttons
    bindGA(body, (bindElement, srcElement) => {
        const { productName, masterId, productColor, productPrice, variationGroupId } = getProductData(srcElement);
        const { category_name: categoryName } = uData;

        // Product "view"
        sendEventGAWithContext({
            event_category: ECOMMERCE,
            event_action: 'view_detail',
            event_label: normalize([productName, masterId, productColor]),
            // GA4
            event_name: VIEW_ITEM,
            product_id: masterId ? [masterId] : [],
            product_name: productName ? [productName] : [],
            product_category: categoryName ? [categoryName] : [],
            product_price: productPrice ? [productPrice] : [],
            product_quantity: [1],
            product_variant: productName && variationGroupId ? [[productName, variationGroupId]] : [],
            product_color: productColor ? [productColor] : [],
            pdp_type: 'quickview'
        }, srcElement);

        return {
            event_category: 'quickview',
            event_action: [productName, masterId],
            event_label: productColor
        };
    }, {
        retriggerEvent: false,
        bindOptions: {
            targetSelectors: ['.quickview']
        }
    });

    // Email Submission
    bindGA(body, {
        event_category: SUBMISSIONS,
        event_action: 'email submit',
        event_label: pageType
    }, {
        bindOptions: {
            targetSelectors: ['.subscribe-email', '.email-submit-button']
        }
    });

    // Email Submission success
    bindGA(body, () => {
        const newsletterTitleElement = queryFirst('.footer-newsletter-title');
        if (!newsletterTitleElement) return false;
        const newsletterTitle = newsletterTitleElement.innerText;

        return {
            // GA4
            event_name: NEWSLETTER_SIGNUP_COMPLETE,
            newsletter_title: newsletterTitle
        };
    }, {
        retriggerEvent: false,
        eventType: 'subscribe:success'
    });

    // Email Submission fail
    bindGA(body, () => {
        const footerFailureReasonElm = queryFirst('#signup-error');
        const lightboxFailureReasonElm = queryFirst('.flyin-email-errors');

        if (!footerFailureReasonElm && !lightboxFailureReasonElm) return false;

        const footerFailureReason = footerFailureReasonElm ? footerFailureReasonElm.innerText.trim() : '';
        const lightboxFailureReason = lightboxFailureReasonElm ? lightboxFailureReasonElm.innerText.trim() : '';

        return {
            // GA4
            event_name: NEWSLETTER_SIGNUP_FAILURE,
            failure_reason: footerFailureReason || lightboxFailureReason,

            // GA360
            event_category: SUBMISSIONS,
            event_action: 'email fail',
            event_label: footerFailureReason || lightboxFailureReason
        };
    }, {
        retriggerEvent: false,
        eventType: 'subscribe:fail'
    });

    bindGA(body, (bindElement, srcElement, e) => {
        const { optin, error } = e.data;
        const label = optin ? 'opt-in' : 'opt-out';
        const failureFields = {};
        if (!optin) {
            failureFields.failure_reason = error || 'subscription opt-out';
        }

        return {
            // GA4
            event_name: optin ? NEWSLETTER_SIGNUP_COMPLETE : NEWSLETTER_SIGNUP_FAILURE,
            newsletter_title: 'email gate',
            ...failureFields,
            // GA360
            event_category: 'email gate entries',
            event_action: 'submit',
            event_label: label
        };
    }, {
        retriggerEvent: false,
        eventType: 'emailgate:submit'
    });

    // Contact Us Submit
    bindGA(queryFirst('.subscribe-contact-us'), (srcElement) => {
        // GA4
        return {
            event_name: 'contact_us_submit',
            click_text: getText(srcElement)
        };
    }, {
        retriggerEvent: false
    });

    // MiniCart Remove Links
    bindGA(body, (bindElement, srcElement) => {
        const { name, pid, tealium } = srcElement.dataset;
        let productData = {};

        try {
            productData = JSON.parse(tealium);
        } catch (e) {
            // JSON.parse failed
        }

        return {
            event_name: 'remove_from_cart',
            event_category: ECOMMERCE,
            event_action: 'remove from cart',
            event_label: [name, pid],
            ...productData
        };
    }, {
        bindOptions: {
            targetSelectors: ['.remove-product-btn']
        }
    });

    // Start a Return
    bindGA(queryAll('.check-return-btn'), {
        event_category: SUBMISSIONS,
        event_action: 'start a return',
        event_label: 'returns'
    });

    // Find In Store
    bindGA(body, () => ({
        event_category: 'submissions',
        event_action: 'find a store submit',
        event_label: 'pick up in store'
    }), {
        bindOptions: {
            targetSelectors: ['.find-store-submit']
        }
    });

    // Select Store - PLP and Cart
    bindGA(body, (bindElement, srcElement) => {
        const { storeId, storeName } = srcElement.dataset;

        return {
            event_category: 'select store: bopis',
            event_action: 'click',
            event_label: [storeName, storeId]
        };
    }, {
        bindOptions: {
            targetSelectors: ['.btn-select-store', '.pick-up-confirm-button']
        }
    });

    // Video Impressions - Handler
    const getVideoImpressions = throttle(() => {
        allVideos.forEach(video => {
            if (!video.hasImpression && isInViewport(video)) {
                video.hasImpression = true;

                sendEventGA({
                    event_category: 'videos',
                    event_action: 'impression',
                    event_label: catType
                });
            }
        });

        return false;
    }, 250, () => false);

    // Video Impressions
    bindGA(document, getVideoImpressions, {
        eventType: 'scroll',
        retriggerEvent: false
    });

    // Video Clicks (Support code)
    bind(body, 'click', (e, srcElement) => {
        const video = srcElement.closest('video') || queryFirst('video', srcElement.parentElement);

        if (video) video.gaClick = video.interaction = true;
    }, {
        targetSelectors: ['video', '.play-video']
    });

    // Video Plays
    bindGA(body, (bindElement, srcElement) => {
        if (!srcElement.gaClick) return false;

        srcElement.gaClick = false;

        return {
            event_category: 'videos',
            event_action: 'play',
            event_label: catType
        };
    }, {
        eventType: 'play',
        retriggerEvent: false,
        bindOptions: {
            targetSelectors: ['video']
        }
    });

    // Video Pauses
    bindGA(body, (bindElement, srcElement) => {
        if (!srcElement.gaClick) return false;

        srcElement.gaClick = false;

        return {
            event_category: 'videos',
            event_action: 'pause',
            event_label: catType
        };
    }, {
        eventType: 'pause',
        retriggerEvent: false,
        bindOptions: {
            targetSelectors: ['video']
        }
    });

    // Video ended event
    const videoEnded = e => {
        const { target } = e;

        if (!target.interaction) return;

        target.removeEventListener('ended', videoEnded);

        sendEventGA({
            event_category: 'videos',
            event_action: 'complete',
            event_label: catType
        });
    };

    // Monitor for video time
    const videoMonitor = e => {
        const { target } = e;
        const { currentTime, duration, wasHalfway, interaction } = target;

        if (!interaction) return;

        const halfTime = duration / 2;

        if (currentTime >= duration || (wasHalfway && currentTime <= halfTime)) {
            target.removeEventListener('timeupdate', videoMonitor);

            sendEventGA({
                event_category: 'videos',
                event_action: 'complete',
                event_label: catType
            });

            return;
        }

        if (!wasHalfway && currentTime >= halfTime) {
            target.wasHalfway = true;
        }
    };

    // Add video listeners
    allVideos.forEach(video => {
        if (video.loop) {
            video.addEventListener('timeupdate', videoMonitor);
        } else {
            video.addEventListener('ended', videoEnded);
        }
    });

    // Check for video impressions when loaded
    getVideoImpressions();

    const notFound = queryFirst('.not-found-container');
    if (notFound) {
        sendEventGA(normalizePayload({
            event_name: 'error_404'
        }));
    }

    // Fire upon shoppable media CTA clicked
    bindGA(body, (bindElement, srcElement) => {
        const { topNav } = bindElement.dataset;
        return {
            event_name: NAVIGATION,
            nav_location: SHOPPABLE_MEDIA_WIDGET,
            click_text: srcElement.title,
            click_url: srcElement.href,
            nav_breadcrumbs: topNav
        };
    }, {
        bindOptions: {
            targetSelectors: ['.shoppable-media-cta']
        }
    });
}

initEvents();
