'use strict';

const { bindGA, normalize } = require('./util/analytics-util');
const { getProductData, hasClass, queryFirst, queryAll, getISProductData, getISProductContainer } = require('lilly/domUtil');
const { PDP_INTERACTION, ADD_TO_CART } = require('./util/constants');

const ARROW_CLICKS = 'arrow clicks';
const ECOMMERCE = 'ecommerce';
const PDP_SELECTIONS = 'pdp selections';
const SIZE_GUIDE = 'size guide';
const SWATCH_CLICKS = 'swatch clicks';
const WISHLIST_BUTTONS = 'wishlist buttons';
const AFTERPAY_INFO = 'afterpay info';
const NOTIFY_ME_SUBMIT = 'notify me - email submit';

let fitPredictorClicked = false;

/**
 * Analytics for All Product-Related Pages
 */
function initEvents() {
    const { body } = document;
    const { utag_data: uData = {} } = window;

    let productAdded = false;

    // Fit Predictor - Click
    bindGA(body, (bindElement, srcElement) => {
        fitPredictorClicked = true;

        const { productName, masterId, productColor } = getProductData(srcElement);

        return {
            event_category: 'fit predictor click',
            event_action: [productName, masterId],
            event_label: productColor
        };
    }, {
        retriggerEvent: false,
        bindOptions: {
            targetSelectors: ['.fp-logo-container', '.fp-info-container']
        }
    });

    // Fit Predictor - Size Selected
    bindGA(body, (bindElement, srcElement) => {
        if (!fitPredictorClicked) return;

        const { productName, masterId, productColor } = getProductData(srcElement);

        return {
            event_category: 'fit predictor recommendation',
            event_action: [productName, masterId],
            event_label: productColor
        };
    }, {
        eventType: 'fitPredictorSizeSelected',
        isCustomEvent: true
    });

    // Size Guides
    bindGA(body, (bindElement, srcElement) => {
        const { productName, masterId, productColor } = getProductData(srcElement);

        return {
            // GA4
            event_name: PDP_INTERACTION,
            interaction_type: SIZE_GUIDE,
            page_product: uData.page_product,

            // GA360
            event_category: SIZE_GUIDE,
            event_action: [productName, masterId],
            event_label: productColor
        };
    }, {
        bindOptions: {
            targetSelectors: ['.size-chart-link']
        }
    });

    // Add to Cart (Unique Basket / Returning Visitor)
    bindGA(body, (bindElement, srcElement) => {
        const { basket_id: basketId, session_id: sessionId } = uData;
        const savedBasketId = localStorage.basketId;
        const savedSessionId = localStorage.sessionId;

        // Unique cart only
        if ((basketId === savedBasketId) && (sessionId === savedSessionId)) return false;

        const selectedSwatch = queryFirst('.product-tile-swatch.selected, .color-attribute .swatch-circle.selected', srcElement);
        const productColor = (selectedSwatch && (selectedSwatch.dataset.swatchName || selectedSwatch.dataset.attrDisplayvalue)) || '';

        // Save the basketId and sessionId to track returning visitors
        localStorage.basketId = basketId;
        localStorage.sessionId = sessionId;

        return {
            event_category: 'unique cart',
            event_action: 'first add',
            event_label: [basketId, productColor]
        };
    }, {
        eventType: 'miniCart:show',
        eventCondition: () => productAdded
    });

    // Trigger GA event on click of add to tote button in shared wishlist
    // Add to Cart
    bindGA(body, (bindElement, srcElement) => {
        // check if it is recommendation ptoduct from Interaction Studio
        const ISProductContainer = getISProductContainer(srcElement);

        const { productName, productId, productColor, productSize, masterId, productPrice, isPreorder, productBadge } =
            ISProductContainer ? getISProductData(ISProductContainer, srcElement) : getProductData(srcElement);

        productAdded = !!productSize;

        // don't send event if size was not selected (It is needed for PDP and Wishlist)
        if (!productAdded && !ISProductContainer) return;

        return Object.assign({
            event_name: ADD_TO_CART,
            product_list: uData.product_list || '',
            category_id: uData.category_id || '',
            product_id: [masterId],
            product_name: [productName],
            product_variant: [[productName, productColor, productSize, productId]],
            product_price: [productPrice],
            product_color: [productColor],
            product_size: [productSize],
            product_sku: [productId],
            product_is_preorder: [isPreorder],
            // GA4
            page_name: uData.page_name,
            product_quantity: ['1'],
            page_type: uData.page_type,
            product_badge: [productBadge],
            loyalty_status: uData.loyalty_status

        }, (productSize ? {
            ecommerce_action: 'add',
            event_category: ECOMMERCE,
            event_action: 'add to cart',
            event_label: [productName, masterId, productColor]
        } : {
            event_category: 'add to cart - no size',
            event_action: [productName, masterId],
            event_label: productColor
        }));
    }, {
        bindOptions: {
            targetSelectors: ['.add-to-cart, .js-tile-add-to-cart, .js-wishlist-add-to-cart']
        }
    });

    // add to cart product set
    bindGA(body, (bindElement, srcElement) => {
        const productSetModal = queryFirst('[id^="productSetModal-"]');
        const productSetItem = queryAll('.custom-set-product.product-set-item', productSetModal);

        const allProductNames = [];
        const allProductPrices = [];
        const allMasterIds = [];
        const productIds = [];
        const productsSize = [];
        const allProductColor = [];
        const productVariants = [];
        const preorderProducts = [];
        const productsBadge = [];
        const productQuantity = [];

        if (!productSetItem.length) return false;

        productSetItem.forEach((product) => {
            const { productName, productId, productColor, productSize, masterId, productPrice, isPreorder, productBadge } = getProductData(product);
            allProductNames.push(productName);
            allProductPrices.push(productPrice);
            allMasterIds.push(masterId);
            productIds.push(productId);
            productsSize.push(productSize);
            allProductColor.push(productColor);
            productsBadge.push(productBadge);
            preorderProducts.push(isPreorder);
            productQuantity.push(1); //by default only 1 product could be added to cart from product highlight
            productVariants.push(normalize([productName, productColor, productSize, productId]));
        });

        return {
            event_name: 'add_to_cart',
            product_list: uData.product_list || '',
            product_list_id: allMasterIds,
            product_list_name: allProductNames,
            product_variant: productVariants,
            product_list_price: allProductPrices,
            product_list_category: 'product highlight - shop the look',
            product_color: allProductColor,
            product_size: productsSize,
            product_list_sku: productIds,
            product_is_preorder: preorderProducts,
            page_name: uData.page_name,
            page_type: uData.page_type,
            product_badge: productsBadge,
            loyalty_status: uData.loyalty_status,
            product_quantity: productQuantity
        };
    }, {
        bindOptions: {
            targetSelectors: ['.add-to-cart-global']
        }
    });

    // Product Swatches
    bindGA(body, (bindElement, srcElement, e) => {
        const { productName, productId, productColor, productSize, masterId, originalPrice, productPrice, variationGroupId, isPreorder } = getProductData(srcElement);
        return {
            // GA4
            event_name: 'swatch_clicks',
            page_type: uData.page_type,
            swatch_product_id: masterId,
            swatch_product_name: productName,
            swatch_product_color: productColor,

            // GA360
            event_action: productColor,
            event_category: SWATCH_CLICKS,
            event_label: [productName, masterId],

            // Product Data
            // Note the prefix. This indicates lazy UDO updates.
            _product_color: [productColor],
            _product_original_price: [originalPrice],
            _product_name: [productName],
            _product_price: [productPrice],
            _product_size: [productSize],
            _product_sku: [productId],
            _product_variant: [[productName, productColor, productSize, productId]],
            variant_group: [variationGroupId || ''],
            product_is_preorder: [isPreorder]
        };
    }, {
        eventType: 'swatchClickEvent',
        isCustomEvent: true
    });

    // See Full Details Links
    bindGA(body, (bindElement, srcElement) => {
        const { productName, masterId, productColor } = getProductData(srcElement);

        return {
            event_category: PDP_SELECTIONS,
            event_action: 'see all product details',
            event_label: [productName, masterId, productColor]
        };
    }, {
        bindOptions: {
            targetSelectors: ['.full-pdp-link']
        }
    });

    // Add to Wishlist
    bindGA(body, (bindElement, srcElement) => {
        const { masterId, originalPrice, productColor, productId, productName, productPrice, productSize } = getProductData(srcElement);

        return {
            event_name: 'add_to_wishlist',
            product_color: [productColor || ''],
            product_id: [masterId || ''],
            product_name: [productName || ''],
            product_original_price: [originalPrice || ''],
            product_price: [productPrice || ''],
            product_size: [productSize || ''],
            product_sku: [productId || ''],

            // 360 attributes
            event_category: WISHLIST_BUTTONS,
            event_action: 'add to wishlist',
            event_label: 'initiate'
        };
    }, {
        eventType: 'addItemToList',
        isCustomEvent: true
    });

    // Add to Wishlist - list selection
    bindGA(body, (bindElement, srcElement) => {
        if (!srcElement.checked) {
            return false;
        }

        const customRadio = srcElement.closest('.custom-radio');
        const isDefaultList = !!queryFirst('.default-wishlist', customRadio);

        return {
            event_category: WISHLIST_BUTTONS,
            event_action: 'select a wishlist',
            event_label: isDefaultList ? 'default list' : 'custom list'
        };
    }, {
        eventType: 'change',
        bindOptions: {
            targetSelectors: ['input[name="listsNameRadio"]']
        }
    });

    // Add to Wishlist -  buttons
    bindGA(body, (bindElement, srcElement) => {
        const isCancelButton = hasClass(srcElement, 'close') || srcElement.id === 'close-add-wishlist-modal';
        const isAddButton = hasClass(srcElement, 'add-list-item');
        const isCreateNewButton = hasClass(srcElement, 'create-new-wishlist-label-button') || hasClass(srcElement, 'js-create-list-radio');
        let action = '';

        if (isCancelButton) {
            action = 'cancel';
        } else if (isAddButton) {
            action = 'add';
        } else if (isCreateNewButton) {
            action = 'create new wishlist';
        } else {
            return false;
        }

        return {
            event_category: WISHLIST_BUTTONS,
            event_action: action
        };
    }, {
        bindOptions: {
            targetSelectors: ['.add-product-to-wishlist-dialog button', '.js-create-list-radio']
        }
    });

    // Product Image Arrows
    bindGA(body, (bindElement, srcElement) => {
        const direction = hasClass(srcElement, 'js-swiper-button-prev') ? 'previous' : 'next';
        const { productName, masterId, productColor } = getProductData(srcElement);

        return {
            event_category: ARROW_CLICKS,
            event_action: direction,
            event_label: [productName, masterId, productColor],
        };
    }, {
        bindOptions: {
            targetSelectors: ['.js-swiper-button-prev', '.js-swiper-button-next']
        }
    });

    // Product Image Swipes
    bindGA(body, (bindElement, srcElement, e) => {
        const { direction } = e.detail;
        const { productName, masterId, productColor } = getProductData(srcElement);

        return {
            event_name: PDP_INTERACTION,
            event_category: ARROW_CLICKS,
            event_action: `mobile swipe ${direction}`,
            interaction_type: 'carousel click',
            event_label: [productName, masterId, productColor],
            page_product: uData.page_product
        };
    }, {
        eventType: 'swiperSlideChange',
        retriggerEvent: false,
        bindOptions: {
            targetSelectors: ['.js-swiper-main']
        }
    });
    // Product Image Zoom
    bindGA(queryFirst('.js-pdp-lightbox-modal'), (bindElement, srcElement, e) => {
        const { productName, masterId, productColor } = getProductData(srcElement);

        return {
            event_name: PDP_INTERACTION,
            interaction_type: 'carousel zoom',
            event_label: [productName, masterId, productColor],
            page_product: uData.page_product
        };
    }, {
        eventType: 'shown.bs.modal',
        isCustomEvent: true
    });

    // Notify Me
    bindGA(body, (bindElement, srcElement) => {
        const { productName, masterId, productColor, productSize } = getProductData(srcElement);

        return {
            // GA4
            event_name: 'notify_me_product',
            interaction_type: 'notify me - click',
            page_product: uData.page_product,
            oos_product_details: [masterId, productColor, productSize],

            // GA360
            event_category: 'notify me',
            event_action: [productName, masterId],
            event_label: [productColor, productSize]
        };
    }, {
        bindOptions: {
            targetSelectors: ['.notify-me-btn, .js-product-tile-notify-me-btn']
        }
    });

    // Notify Me - Email Submit
    bindGA(body, (bindElement, srcElement) => {
        const productID = queryFirst('.js-notify-sku').value;
        const targetElement = queryFirst(`[data-product-id="${productID}"]`).closest('.js-product-tile');
        const { productName, masterId, productColor, productSize } = getProductData(targetElement);

        return {
            // GA4
            event_name: 'notify_me_product',
            interaction_type: NOTIFY_ME_SUBMIT,
            page_product: uData.page_product,
            oos_product_details: [masterId, productColor, productSize],

            // GA360
            event_category: NOTIFY_ME_SUBMIT,
            event_action: [productName, masterId],
            event_label: [productColor, productSize]
        };
    }, {
        bindOptions: {
            targetSelectors: ['.notify-me-submit']
        }
    });

    // Afterpay - Info
    bindGA(body, () => {
        return {
            // GA4
            event_name: PDP_INTERACTION,
            interaction_type: AFTERPAY_INFO,
            page_product: uData.page_product,

            // GA360
            event_category: AFTERPAY_INFO,
            event_action: 'clicks'
        };
    }, {
        retriggerEvent: false,
        bindOptions: {
            targetSelectors: ['.afterpay-link']
        }
    });
}

initEvents();
