module.exports = {
    breakpoints: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 1024,
        xl: 1362
    },

    // Stores
    PREFERRED_STORE_ID: 'PREFERRED_STORE_ID',

    // Constant values
    EMPTY_STRING: '',
    ONE_SIZE_ID: window?.lillyUtils?.constants?.ONE_SIZE || '1 SZ',
    ONE_SIZE_LABEL: window?.lillyUtils?.resources?.ONE_SIZE || 'One size',

    // Forms
    CHANGED_CLASS: 'm-changed',
    FILLED_CLASS: 'm-filled',

    // DOM Class
    ACTIVE_CLASS: 'active',
    HIDDEN_CLASS: 'd-none',
    INVALID_CLASS: 'is-invalid',
    INVISIBLE_CLASS: 'invisible',
    NO_SCROLL_CLASS: 'no-scroll',
    SELECTED_CLASS: 'selected',
    SHOW_CLASS: 'show',
    SHOW_ALL_CLASS: 'show-all',
    NOT_AVAILABLE_CLASS: 'not-available',
    D_FLEX: 'd-flex',
    DISPLAY_CLASS: 'd-block',
    INACTIVE_CLASS: 'inactive',
    MENU_OPENED: 'm-menu-opened',
    LOADED_CLASS: 'loaded',
    SCROLLED_CLASS: 'scrolled',

    // Product status
    PREORDER_STATUS: 'PREORDER',
    BACKORDER_STATUS: 'BACKORDER',
    IN_STOCK_STATUS: 'IN_STOCK',
    NOT_AVAILABLE_STATUS: 'NOT_AVAILABLE',

    // Custom DOM events

    // Control keys

    // Event KeyCodes
    KEYCODE_TAB: 9,
    KEYCODE_ESCAPE: 27,
    KEYCODE_UP: 38,
    KEYCODE_DOWN: 40,
    KEYCODE_ENTER: 13,
    EVENT_KEY_ENTER: 'Enter',
    EVENT_KEY_TAB: 'Tab',

    // Video constants
    PLAYED_CLASS: 'played',
    MUTED_CLASS: 'muted',
    UNMUTED_CLASS: 'unmuted',
    WAS_PLAYING_CLASS: 'resize-paused',
    TIMEOUT_100: 100,

    // Search types
    SEARCH_TYPE: 'searchType',
    SEARCH_SUBMIT: 'search submit',
    SEARCH_SUGGESTIONS: 'search suggestions',
    POPULAR_SEARCHES: 'popular searches',
    SEARCH_HISTORY: 'SEARCH_HISTORY',

    // Refinement Types
    REFINEMENT_TYPE_CATEGORY: 'category',
    // Animation constants
    ANIM_TIME_INSTANT: 0,
    ANIM_TIME_QUICK_1: 150,
    ANIM_TIME_QUICK_2: 300,
    ANIM_TIME_STANDARD_1: 600,
    ANIM_TIME_STANDARD_2: 1200,
    ANIM_TIME_STANDARD_3: 2000,
    ANIM_TIME_STANDARD_4: 3000,
    TIMEOUT_1000: 1000,
    ANIM_EASE_LINEAR: 'cubic-bezier(0, 0, 1, 1)',
    ANIM_EASE_IN: 'cubic-bezier(0.9, 0, 0.7, 1)',
    ANIM_EASE_OUT: 'cubic-bezier(0.3, 0, 0.1, 1)',
    ANIM_EASE_IN_OUT: 'cubic-bezier(0.3, 0, 0.7, 1)',
    EMAIL_REGEX: /^(?=^.{6,80}$)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]*[a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/,
    PHONE_NUMBER_REGEX: /^\+?1?\s*\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/,

    IS_SAFARI: /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
};
